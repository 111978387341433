import React from 'react';

const Docs = () => {
  return (
    <iframe
      src="https://docs.llmhub.me/pages/connect/%E4%B8%80%E3%80%81%E5%AF%B9%E6%8E%A5%20Hent%20AI/1.%20%E5%BF%AB%E9%80%9F%E6%8E%A5%E5%85%A5.html"
      style={{ width: '100%', height: '100vh', border: 'none' }}
    />
  );
};


export default Docs;
